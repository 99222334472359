<template>
    <div class="container">
        <div class="header pt-5">
            <div class="logos flex-betweeb">
                <div class="row">
                    <div class="pt-5">
                        <img
                            :src="`./../../new_logo_landscape_white.png`"
                            style="
                                width: 178px;
                                height: 55px;
                                object-fit: contain;
                            "
                        />
                    </div>
                    <div class="pt-5 pl-5">
                        <img
                            :src="`./../../logo_abe.png`"
                            style="
                                width: 100px;
                                height: 85px;
                                object-fit: contain;
                            "
                        />
                    </div>
                </div>
                <div class="pt-5" style="margin-top: 15px">
                    <a
                        href="https://meiaentradadigital.com.br/contato"
                        target="_blank"
                    >
                        <div class="btn">
                            <span class="centrarlizar_btn">
                                <p class="fale">FALE CONOSCO</p>
                            </span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderGratuito",
};
</script>

<style scoped>
.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;
    width: 161px;
    height: 46px;
    background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
    border: 2px solid #fa9500;
    border-radius: 0.5rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    transition: box-shadow 0.3s ease;
}
.btn:hover {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.8);
    background: linear-gradient(90deg, #ffad52 0%, #ffd56b 100%);
}
.btn:active {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.8);
}
.fale {
    font-family: "Calibri";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #1c4173;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.centrarlizar_btn {
    display: flex;
    align-items: baseline;
    height: 20px;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
    .btn {
        width: 100px;
        padding: 0;
        height: 30px;
    }
    .fale {
        font-size: 13px;
    }
    .logos{
        display: flex;
        align-items: center;
    }
}
</style>
